@import '~bootstrap-scss';

// font awesome 5 pro
@import "node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-pro/scss/light.scss";

@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}


h2 {
  margin-bottom: 0;
  font-size: 24px;
}

h4 {
  font-size: 15px;
}

.container-fluid {
  max-width: 1300px;
}

#loading {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1051; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  img {
    top: 50%;
    left: 50%;
    position: absolute;
    margin-left: -470px;
    margin-top: -200px;
  }
}

.hidden {
  display: none;
}

.navbar {
  margin: 5px 0;
  padding: 0;

  ul {
    float: right;
  }
}

.footer {
  color: #ffffff;
}

.filter-info {
  color: #888888;
  font-size: small;
  cursor: pointer;
}

.filter-box {

  .btn {
    background-color: transparent;
    font-size: 12px;
    cursor: pointer;
    color: #000;
  }

  .active {
    color: #fff;
    background-color: #007bff;
  }

  .filter {
    .badge {
      margin: 0 6px;
    }
  }

  .critical-text {
    color: red;
  }

  #search {
    width: 100%;
    height: 35px;
    padding-left: 10px;
  }

  .translation {
    float: right;
  }

  #agency-filter {
    height: 35px;
    width: 100%;
    padding: 5px;
  }

  .btn {
    .filter-count {
      position: absolute;
      top: -15px;
      right: -10px;
      padding: 2px 10px;
      font-size: 10px;
      color: #000;
      border-radius: 0;
    }

    &#critical-count {
      .filter-count {
        background-color: #c53929;
        color: #fff;
      }
    }

  }


}

#count-box {
  text-align: right;
  margin: 5px 0;
  font-size: 14px;
}

.customer-container {

  .customer {
    background-color: #fff;
    padding: 20px;
    border-top: 1px solid #eaeaea;
    margin-bottom: 0;

    .collapsed-view {
      padding-top: 20px;
    }

    .title {
      font-size: 13px;
      color: #555555;
    }

    .stats {
      font-size: 13px;
      color: #888;
    }

    .badge {
      font-size: 10px;
      border-radius: 0;
    }

    .ad {
      background-color: #fff;
      border: 1px solid black;
      overflow: hidden;
      padding: 5px;
      font-size: 11px;
      margin-top: 5px;
      margin-bottom: 3px;
    }

    &.customer-disabled {
      background-color: #ffeeee;
    }

    &.customer-enabled {
      border-top: 1px solid #eeeeee;
    }

    &.customer-paused {
      border-top: 1px solid #ff0000;
    }

    .customer-url {
      color: #3c763d;
      font-weight: bold;
    }

    .customer-type {
      font-size: 9px;
      color: #888;
    }

    .google-status {
      color: #c53929;
      font-size: 10px;
      padding-left: 10px;
    }

    .sparkline {
      height: 50px;
      width: 100%;
      margin-top: 5px;
      font-size: 10px;
    }

    .ads {
      overflow: hidden;
    }

    .timeline {
      height: 1px;
      padding-left: 65px;

      > div {
        position: relative;
        top: -21px;
        float: left;
      }

      > div > i {
        float: right;
        cursor: pointer;
        position: relative;
        top: 3px;
      }

      > div > div {
        float: right;
        width: 8px;
        height: 6px;
        background-color: #dddddd;
        border-radius: 0 0 50% 50%;
        cursor: pointer;
      }
    }

    .keyword,
    .negative-keyword,
    .search-term,
    .geo-target {
      font-size: 14px;
      display: block;
      cursor: pointer;
      border: 1px solid transparent;
    }

  }

  .schedule {

    position: relative;
    top: 10px;

    .badge {
      cursor: pointer;
      display: block;
      width: 100%;
    }
  }


  .warnings, .alerts {
    .badge {
      cursor: pointer;
      display: block;
      width: 100%;
      margin-bottom: 2px;
      position: relative;
      top: 12px;
    }
  }

  .keyword.status-forbidden,
  .keyword.status-too_broad,
  .keyword.status-ignore {
    background-color: #ff7777;
    color: #fff;
  }

  .keyword.type-unwanted,
  .keyword.type-negative {
    background-color: #c53929;
    color: #fff;
  }

  .keyword.type-wanted,
  .keyword.type-geo {
    color: #888888;
  }

  .keyword.status-customer_negative,
  .search-term.status-removed,
  .search-term.status-too_broad,
  .search-term.status-ignore,
  .search-term.status-negative,
  .search-term.status-shared_negative,
  .search-term.status-customer_negative,
  .search-term.category-unwanted {
    text-decoration: line-through;
  }

  .keyword:hover,
  .negative-keyword:hover,
  .search-term:hover,
  .geo-target:hover {
    border-color: black;
  }

  .keyword .keyword_status {
    background-color: green;
    width: 5px;
    height: 5px;
    float: left;
    position: relative;
    top: 7px;
    margin-right: 5px;
  }

  .categorized-as {
    font-size: 11px;
    margin-left: 10px;

    &.fa-thumbs-down {
      color: #c53929;
    }

    &.fa-thumbs-up {
      color: #6666ff;
    }
  }

  .display-url {
    color: #6666ff;
  }

  .show-all {
    cursor: pointer;
    font-size: 10px;
    color: #6666ff;
    margin-top: 3px;
  }
}


#keyword-form {

  .scope {
    margin: 10px;
  }

  .modal-title {
    font-weight: bold;
    margin: 2px;
  }

  .actions {
    width: 100%;
  }

  .global-actions button {
    width: 33%;
  }

  #phrase, #phrase_en {
    width: 75%;
    display: inline;
  }

  #phrase_en {
    background-color: #ddd;
  }

  #languages,
  #language_en {
    width: 20%;
    display: inline;
  }

  .quick-translate {
    width: 20%;
    font-size: 15px;
  }

  .cancel {
    margin-top: 10px;
  }
}

.attention_status {
  font-size: 18px;
  color: #000;
  cursor: pointer;
  position: relative;
  top: 3px;
}

.attention_status {
  &.need_attention {
    color: #c53929;
  }

  &.okayed {
    color: green;
  }
}

.load-more-customers {
  display: none;
}

.send-message {
  color: #eee;
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-left: 3px;
  margin-top: 5px;
  cursor: pointer;
}

.add-comment {
  color: #eee;
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-left: 3px;
  margin-top: 5px;
  cursor: pointer;
}

.comment-form {
  float: left;
  list-style: none;
}

.hidden-form {
  visibility: hidden;
}

.show-form {
  visibility: visible !important;
}

.send-alert {
  color: #ff0000;
}

#comment-form,
#message-form {

  label {
    width: 100%;
  }

  textarea, input {
    width: 100%;
  }

  #message_body {
    height: 400px;
  }
}

table.transactions, table.phrases, table.logs, table.jobs {
  background-color: #fff;
}

.form-control,
.modal-header,
.modal-content,
.btn {
  border-radius: 0;
}

#warning_body {
  font-size: 14px;
}

#requests {

  .user {
    width: 400px;
    overflow: hidden;
    padding: 20px;
    float: left;
    background-color: white;
    margin: 10px;
  }

  .user .request {
    font-size: 10px;
  }
}

.autopilot {
  color: #aaa;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  top: 2px;
  left: 5px;

  &.on {
    color: #00aa00;
  }
}


#logs {

  .filter-box select {
    width: 235px;
  }

  .details-textarea {
    width: 100%;
    height: 200px;
  }

  #table-container {
    td:last-child {
      text-align: right;
    }
  }

  .details-header-data {
    width: 100%;
  }

  .show-details {
    cursor: pointer;
    color: #007bff;
  }

  .activity-dot {
    vertical-align: middle;
  }
}

.comment-delete {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  cursor: pointer;
}

.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 0;
  padding: 1rem;
  background-image: url('/background.jpg');
  background-position: center;
  background-color: #35649c;
  background-size: cover;


  #sign-in {
    padding: 30px 0;
    text-align: center;
  }

  #sign-in img {
    width: 90%;
    cursor: pointer;
  }

  .login-form {
    width: 100%;
    max-width: 320px;
    margin: auto;
    border-top: 0;
    transform: rotate(0deg);
    border: none;

    .card-header {
      font-weight: 400;
      color: #fff;
    }

    .card-title {
      font-weight: 400;
    }
  }

  .login-logo {
    height: 36px;
    width: auto;
    margin: 10px 0 25px;
  }
  .counter-section i { display:block; margin:0 0 10px}
  .counter-section span.counter { font-size:40px; color:#000; line-height:60px; display:block; font-family: "Oswald",sans-serif; letter-spacing: 2px}
  .counter-title{ font-size:12px; letter-spacing:2px; text-transform: uppercase}
  .counter-icon {top:25px; position:relative}
  .counter-style2 .counter-title {letter-spacing: 0.55px; float: left;}
  .counter-style2 span.counter {letter-spacing: 0.55px; float: left; margin-right: 10px;}
  .counter-style2 i {float: right; line-height: 26px; margin: 0 10px 0 0}
  .counter-subheadline span {float: right;}

  .medium-icon {
    font-size: 40px !important;
    margin-bottom: 15px !important;
  }

  .container{
    margin-top:200px;
  }


}

.data {
  width: 45%;
  text-align: right;
}

.trend {
  width: 45%;
  text-align: left;
  position: relative;
  left: 10px;
}

.customer-color {
  border-bottom: 5px solid #dc3545;
}

.spend-color {
  border-bottom: 5px solid #ffc107;
}

.kick-color {
  border-bottom: 5px solid #28a745;
}
.filter-box select {
  padding: 5px;
  float: right;
}
.summary-box {
  display: flex;
  font-size: 25px;
  text-align: right;
  margin-bottom: 10px;
  div {
    flex-grow: 1;
    width: 60%;
  }
  .trend {
    font-size: 15px;
    text-align: left;
    top: 8px;
    width: 39%;
  }
}



